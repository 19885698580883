import { FC } from "react";
import { useLocation } from "react-router-dom";

type FooterProps = {};

const Footer: FC<FooterProps> = ({}) => {
  const location = useLocation();

  if (location.pathname === "/login") return null;
  if (location.pathname.startsWith("/map/")) return null;

  return (
    <div className="w-screen h-[var(--footer-height)] fixed bottom-0 left-0 bg-black flex justify-center items-center">
      <p className="text-xs text-slate-400">==FOR INTERNAL TESTING USE ONLY== Unauthorized use, disclosure, copying, or distribution of any information contained on this page is strictly prohibited.</p>
    </div>
  );
};

export default Footer;
