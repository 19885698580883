import { FC } from "react";

import { GeneratorFormProps } from "../../types";

import BaseGeneratorForm from "./BaseGeneratorForm"

const DragonflyGeneratorForm: FC<GeneratorFormProps> = ({ onSubmit }) => {
  const options = [
    { value: 'Device', label: 'Device' },
    { value: 'Time', label: 'Time' },
    { value: 'Latitude', label: 'Latitude' },
    { value: 'Longitude', label: 'Longitude' },
    { value: 'Date', label: 'Date' },
    { value: 'Bands', label: 'Bands'},
    { value: 'Bandwidth', label: 'Bandwidth'},
    { value: 'Cell Id', label: 'Cell Id'},
    { value: 'Earfcn', label: 'Earfcn'},
    { value: 'Network Name', label: 'Network Name'},
    { value: 'Pci', label: 'Pci'},
    { value: 'Lte State', label: 'Lte State'},
    { value: 'Rsrp', label: 'Rsrp'},
    { value: 'Rsrq', label: 'Rsrq'},
    { value: 'Rssnr', label: 'Rssnr'},
    { value: 'Mnc', label: 'Mnc'},
    { value: 'Mcc', label: 'Mcc'}
    // Add other options here
  ];

  return <BaseGeneratorForm onSubmit = {onSubmit} options = {options}/>

};

export default DragonflyGeneratorForm;
