import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { SecondaryButton, TextButton } from "./Buttons";
import { useResetRecoilState } from "recoil";
import { UserState } from "../atoms";

type NavbarProps = {};

const Navbar: FC<NavbarProps> = ({}) => {
  const navigate = useNavigate();
  const reset = useResetRecoilState(UserState);

  const handleLogout = () => {
    reset();
    localStorage.clear();
    navigate("/login");
  };
  return (
    <div className="w-screen h-[var(--navbar-height)] flex justify-between items-center bg-slate-600 text-white fixed top-0 left-0 p-2 z-[10]">
      <div className="flex justify-center items-center space-x-2">
        <div className="flex justify-center items-center space-x-2 cursor-pointer" onClick={() => navigate("/")}>
          <img src="images/pollen-icon.png" alt="Pollen" className="h-6 invert rounded-md" />
          <p className="text-xl font-extrabold">Maps</p>
        </div>
        <SecondaryButton onClick={() => navigate(-1)}>Generate</SecondaryButton>
        <SecondaryButton onClick={() => navigate("/maps")}>Maps</SecondaryButton>
      </div>
      <SecondaryButton onClick={handleLogout}>Logout</SecondaryButton>
    </div>
  );
};

export default Navbar;
