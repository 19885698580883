import React, { FC, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import { FormState } from "../../types";
import { PrimaryButton } from "../Buttons";
import LoadingIndicator from "../LoadingIndicator";
import { useNavigate } from "react-router-dom";
import SelectDropdown from "../inputs/SelectDropdown";
import { GeneratorFormProps } from "../../types";
import { StringParam, useQueryParams, JsonParam, BooleanParam } from "use-query-params";
import { IoMdArrowDropdown } from "react-icons/io";
import ManualStringArrayCreator from "../ManualStringArrayCreator";
import _ from "lodash";

const HoneybeeGeneratorForm: FC<GeneratorFormProps> = ({ onSubmit }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const timezones = moment.tz.names();

  const [params, setParams] = useQueryParams({
    plot_type: StringParam,
    devices: JsonParam,
    map_color_var: StringParam,
    map_color_scale: StringParam,
    map_color_mapping: JsonParam,
    map_color_range: JsonParam,
    map_hover_data: JsonParam,
    map_suffix: StringParam,
    timestamp_output: BooleanParam,
    map_style: StringParam,
    flower_locations: BooleanParam,
    filter_query: StringParam,
    save_location: StringParam,
    timezone: StringParam,
    hex_aggregation: JsonParam,
    force_discrete_plot: BooleanParam,
    // UI control params
    use_hex_aggregation: BooleanParam,
    use_advanced_map_options: BooleanParam,
    force_uncached: BooleanParam,
  });

  const HEX_RESOLUTIONS = [5, 9, 11];
  const AGG_FUNCTIONS = ["mean", "min", "max", "mode", "sum", "std", "var", "count", "count-unique"];

  const admin = useMemo(() => {
    if (localStorage.getItem("admin")) return true;
    return false;
  }, []);

  function validateFormState(formState: FormState): boolean {
    let isValid = true;

    if (!params.devices) return false;
    if (![...params.devices].filter((bee) => (!_.isEmpty(bee) ? true : false))) {
      isValid = false;
    }

    for (const bee of params.devices) {
      if (!bee.device.trim()) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  const getFormState = (): FormState | null => {
    if (!params.plot_type) return null;

    let form: FormState = {
      plot_type: params.plot_type,
      devices: params.devices || [],
      map_color_var: params.map_color_var || "Time",
      map_color_scale: params.map_color_scale || "Purples",
      map_color_mapping: params.map_color_mapping,
      map_color_range: params.map_color_range,
      map_hover_data: params.map_hover_data,
      map_suffix: params.map_suffix || undefined,
      timestamp_output: params.timestamp_output || true,
      map_style: params.map_style || "open-street-map",
      flower_locations: params.flower_locations || true,
      filter_query: params.filter_query || undefined,
      save_location: params.save_location || "aws",
      timezone: params.timezone || "UTC",
      hex_aggregation: params.hex_aggregation,
      force_discrete_plot: params.force_discrete_plot || false,
    };

    if (form.map_color_var === "Cell ID") {
      form.force_discrete_plot = true;
    }

    if (form.map_color_var === "Rx Channel") {
      form.force_discrete_plot = true;
    }

    if (form.map_color_var === "Flower") {
      form.map_color_mapping = {
        "Not Attached": "black",
        Unknown: "brown",
      };
    }

    if (form.map_color_var === "Cell ID") {
      form.map_color_mapping = {
        "Not Attached": "black",
        Unknown: "brown",
      };
    }

    return form;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const formState = getFormState();
    setLoading(true);

    if (formState && validateFormState(formState)) {
      await onSubmit(formState);
    }

    setLoading(false);
  };

  const map_color_selector = useMemo(() => {
    if (!params.plot_type) return null;
    return (
      <SelectDropdown id="map_color" className="w-full p-2 border border-slate-700 bg-slate-700 rounded text-white" value={params.map_color_var} onChange={(e: any) => setParams({ ...params, map_color_var: e.target.value })}>
        <option value="Device">Device</option>
        <option value="Time">Time</option>
        <option value="Latitude">Latitude</option>
        <option value="Longitude">Longitude</option>
        <option value="H3 Hex Res5">H3 Hex Res5</option>
        <option value="H3 Hex Res9">H3 Hex Res9</option>
        <option value="H3 Hex Res11">H3 Hex Res11</option>
        <option value="Date">Date</option>
        <option value="Activation Code">Activation Code</option>
        <option value="All Time Pollen Drop Count">All Time Pollen Drop Count</option>
        <option value="App Version">App Version</option>
        <option value="Carrier1">Carrier1</option>
        <option value="Carrier1 Name">Carrier1 Name</option>
        <option value="Carrier1 Pollen">Carrier1 Pollen</option>
        <option value="Carrier1 Radio Access Technology">Carrier1 Radio Access Technology</option>
        <option value="Carrier2">Carrier2</option>
        <option value="Carrier2 Name">Carrier2 Name</option>
        <option value="Carrier2 Pollen">Carrier2 Pollen</option>
        <option value="Carrier2 Radio Access Technology">Carrier2 Radio Access Technology</option>
        <option value="Cellular In Use">Cellular In Use</option>
        <option value="Install Id">Install Id</option>
        <option value="Ios Version">Ios Version</option>
        <option value="Mcc">Mcc</option>
        <option value="Mcc2">Mcc2</option>
        <option value="Mnc">Mnc</option>
        <option value="Mnc2">Mnc2</option>
        <option value="Model Name">Model Name</option>
        <option value="Pollen Attached">Pollen Attached</option>
        <option value="Pollen Sim Installed">Pollen Sim Installed</option>
        <option value="Pollen Sim In Use">Pollen Sim In Use</option>
        <option value="Primary Carrier Attached">Primary Carrier Attached</option>
        <option value="Reasons For Log">Reasons For Log</option>
        <option value="Validate Or Request">Validate Or Request</option>
        <option value="Weekly Pollen Drop Count">Weekly Pollen Drop Count</option>
        <option value="Wifi In Use">Wifi In Use</option>
      </SelectDropdown>
    );
    return null;
  }, [params]);

  useEffect(() => console.log(params), [params]);

  const handleDisplayNameChange = (index: number, event: any) => {
    const newBees = [...params.devices];
    newBees[index].display_name = event.target.value;
    setParams({ ...params, devices: newBees });
  };

  useEffect(() => {
    if (params.use_hex_aggregation) {
      setParams({ ...params, hex_aggregation: { resolution: 9, agg_function: "mean" } });
    }
  }, [params.use_hex_aggregation]);

  return (
    <div className="flex items-start justify-center overflow-y-scroll h-fit">
      <form onSubmit={handleSubmit} className="p-6 rounded-lg space-y-2 bg-slate-700/30 shadow-lg">
        <div className="pb-4">
          {params.devices.map((bee: any, index: number) => (
            <div key={index} className="flex flex-col items-center justify-center space-y-2 pt-2">
              <label htmlFor={`display_name_${index}`} className="block text-white">
                {bee.device}
              </label>
              <input
                id={`display_name_${index}`}
                type="text"
                placeholder="Display Name (optional)"
                className="w-full p-2 border border-slate-700 rounded bg-slate-700 text-white"
                value={bee.display_name || ""}
                onChange={(e) => handleDisplayNameChange(index, e)}
              />
            </div>
          ))}
        </div>
        {/* Bees */}
        {params.devices && (
          <div className="space-y-2">
            <label htmlFor={`start_time`} className="block text-white">
              Start Time
            </label>
            <div className="flex items-center justify-center">
              <input
                id={`start_time`}
                type="datetime-local"
                className="w-full p-2 border border-slate-700 rounded bg-slate-700 text-white"
                value={params.devices[0]?.start_time || ""}
                disabled={params.devices && params.devices.length > 0 && params.devices[0].hasOwnProperty("start_time_offset") ? true : false}
                onChange={(e: any) => {
                  let newBees = [...params.devices] || [];
                  newBees = newBees.map((bee) => ({ ...bee, start_time: e.target.value, start_time_offset: undefined }));
                  setParams({ ...params, devices: newBees });
                }}
              />
              <span className="text-white ml-2">or</span>
              <input
                id={`start_time_offset`}
                type="number"
                disabled={params.devices && params.devices.length > 0 && params.devices[0].start_time ? true : false}
                placeholder="Offset (seconds)"
                className="ml-2 p-2 border border-slate-700 rounded bg-slate-700 text-white"
                value={params.devices[0]?.start_time_offset || ""}
                onChange={(e: any) => {
                  let newBees = [...params.devices] || [];
                  newBees = newBees.map((bee) => ({ ...bee, start_time: undefined, start_time_offset: parseInt(e.target.value) }));
                  setParams({ ...params, devices: newBees });
                }}
              />
            </div>
            <label htmlFor={`end_time`} className="block text-white">
              End Time
            </label>
            <div className="flex items-center justify-center">
              <input
                id={`end_time`}
                type="datetime-local"
                className="w-full p-2 border border-slate-700 rounded bg-slate-700 text-white"
                value={params.devices[0]?.end_time || ""}
                disabled={params.devices && params.devices.length > 0 && params.devices[0].end_time_offset ? true : false}
                onChange={(e) => {
                  let newBees = [...params.devices] || [];
                  newBees = newBees.map((bee) => ({ ...bee, end_time: e.target.value, end_time_offset: undefined }));
                  setParams({ ...params, devices: newBees });
                }}
              />
              <span className="text-white ml-2">or</span>
              <input
                id={`end_time_offset`}
                type="number"
                placeholder="Offset (seconds)"
                disabled={params.devices && params.devices.length > 0 && params.devices[0].end_time ? true : false}
                className="ml-2 p-2 border border-slate-700 rounded bg-slate-700 text-white"
                value={params.devices[0]?.end_time_offset || ""}
                onChange={(e) => {
                  let newBees = [...params.devices] || [];
                  newBees = newBees.map((bee) => ({ ...bee, end_time_offset: parseInt(e.target.value), end_time: undefined }));
                  setParams({ ...params, devices: newBees });
                }}
              />
            </div>
          </div>
        )}

        {/* Timezone */}
        <label htmlFor="timezone" className="block text-white">
          Timezone
        </label>

        <SelectDropdown id="timezone" className="w-full p-2 border border-slate-700 rounded bg-slate-700 text-white" value={params.timezone} onChange={(e: any) => setParams({ ...params, timezone: e.target.value })} defaultValue="UTC">
          <option value="">Select a timezone {"(default UTC)"}</option>
          {timezones.map((timezone) => (
            <option key={timezone} value={timezone}>
              {timezone}
            </option>
          ))}
        </SelectDropdown>

        {/* Map Color */}
        <label htmlFor="map_color" className="block text-white">
          Map Variable
        </label>
        {map_color_selector}

        {/* Map Style */}
        <label htmlFor="map_style" className="block text-white">
          Map Style
        </label>
        <SelectDropdown id="map_style" className="w-full p-2 border border-slate-700 rounded bg-slate-700 text-white" value={params.map_style} onChange={(e: any) => setParams({ ...params, map_style: e.target.value })}>
          <option value="">Select a map style</option>
          <option value="open-street-map">Open Street Map</option>
          <option value="white-bg">White BG</option>
          <option value="carto-positron">Carto Positron</option>
          <option value="carto-darkmatter">Carto Dark Matter</option>
          <option value="stamen-terrain">Stamen Terrain</option>
          <option value="stamen-toner">Stamen Toner</option>
          <option value="stamen-watercolor">Stamen Watercolor</option>
          <option value="basic">Basic</option>
          <option value="streets">Streets</option>
          <option value="outdoors">Outdoors</option>
          <option value="light">Light</option>
          <option value="dark">Dark</option>
          <option value="satellite">Satellite</option>
          <option value="satellite-streets">Satellite Streets</option>
        </SelectDropdown>

        <div className="flex items-center pt-4 pb-2 hover:cursor-pointer cursor-auto" onClick={() => setParams({ ...params, use_advanced_map_options: params.use_advanced_map_options ? false : true })}>
          {/* <input type="checkbox" id="use_advanced_map_options" className="mr-2" checked={params.use_advanced_map_options || false} onChange={(e) => setParams({ ...params, use_advanced_map_options: e.target.checked })} /> */}
          <button className="text-white px-2 text-lg">
            {params.use_advanced_map_options ? (
              <IoMdArrowDropdown />
            ) : (
              <div className="rotate-180">
                <IoMdArrowDropdown />
              </div>
            )}
          </button>
          <label htmlFor="use_advanced_map_options" className="text-white">
            Advanced Map Options
          </label>
        </div>

        {params.use_advanced_map_options && (
          <div className="p-4 bg-slate-800/60 space-y-2 rounded-md">
            {/* Map Color Scale */}
            <label htmlFor="map_color_scale" className="block text-white">
              Map Color Scale
            </label>
            <SelectDropdown id="map_color_scale" className="w-full p-2 border border-slate-700 rounded bg-slate-700 text-white" value={params.map_color_scale} onChange={(e: any) => setParams({ ...params, map_color_scale: e.target.value })}>
              <option value="viridis">viridis</option>
              <option value="plasma">plasma</option>
              <option value="inferno">inferno</option>
              <option value="magma">magma</option>
              <option value="cividis">cividis</option>
              <option value="Greys">Greys</option>
              <option value="Purples">Purples</option>
              <option value="Blues">Blues</option>
              <option value="Greens">Greens</option>
              <option value="Oranges">Oranges</option>
              <option value="Reds">Reds</option>
              <option value="YlOrBr">YlOrBr</option>
              <option value="YlOrRd">YlOrRd</option>
              <option value="OrRd">OrRd</option>
              <option value="PuRd">PuRd</option>
              <option value="RdPu">RdPu</option>
              <option value="BuPu">BuPu</option>
              <option value="GnBu">GnBu</option>
              <option value="PuBu">PuBu</option>
              <option value="YlGnBu">YlGnBu</option>
              <option value="PuBuGn">PuBuGn</option>
              <option value="BuGn">BuGn</option>
              <option value="YlGn">YlGn</option>
            </SelectDropdown>

            {/* Map Color Range */}
            <label htmlFor="map_color_range" className="block text-white">
              Map Color Range
            </label>

            <div className="flex items-center space-x-4">
              <input
                id={`map_color_range_min`}
                type="number"
                placeholder="Min"
                className="w-1/2 p-2 border border-slate-700 rounded bg-slate-700 text-white"
                value={params.map_color_range?.[0] || ""}
                onChange={(e) => {
                  const range = [...(params.map_color_range || [])];
                  range[0] = parseInt(e.target.value);
                  setParams({ ...params, map_color_range: range });
                }}
              />
              <input
                id={`map_color_range_max`}
                type="number"
                placeholder="Max"
                className="w-1/2 p-2 border border-slate-700 rounded bg-slate-700 text-white"
                value={params.map_color_range?.[1] || ""}
                onChange={(e) => {
                  const range = [...(params.map_color_range || [])];
                  range[1] = parseInt(e.target.value);
                  setParams({ ...params, map_color_range: range });
                }}
              />
            </div>

            {/* Map Hover Data */}
            <ManualStringArrayCreator
              title="Map Hover Data"
              values={params.map_hover_data !== undefined ? params.map_hover_data : []}
              onValueSet={(vals) => {
                setParams({ ...params, map_hover_data: vals });
              }}
            />
          </div>
        )}

        {/* Hex  Aggregations */}
        <div className="flex items-center pt-4">
          <input type="checkbox" id="hex_aggregation" className="mr-2" checked={params.use_hex_aggregation || false} onChange={(e) => setParams({ ...params, use_hex_aggregation: e.target.checked })} />
          <label htmlFor="hex_aggregation" className="text-white">
            Hex Aggregation
          </label>
        </div>

        {params.use_hex_aggregation && (
          <div className="space-y-4 pb-4">
            <h3 className="text-white text-lg">Hex Aggregation Settings</h3>
            <SelectDropdown
              id="hex_resolution"
              className="w-full p-2 border border-slate-700 rounded bg-slate-700 text-white"
              value={params.hex_aggregation?.resolution || 9}
              onChange={(e: any) => setParams({ ...params, hex_aggregation: { ...params.hex_aggregation, resolution: parseInt(e.target.value) } })}
            >
              {HEX_RESOLUTIONS.map((res) => (
                <option key={res} value={res}>
                  Res {res}
                </option>
              ))}
            </SelectDropdown>
            <SelectDropdown
              id="agg_function"
              className="w-full p-2 border border-slate-700 rounded bg-slate-700 text-white"
              value={params.hex_aggregation?.agg_function || null}
              onChange={(e: any) => setParams({ ...params, hex_aggregation: { ...params.hex_aggregation, agg_function: e.target.value } })}
            >
              {AGG_FUNCTIONS.map((func) => (
                <option key={func} value={func}>
                  {func}
                </option>
              ))}
            </SelectDropdown>
          </div>
        )}

        {/* Flower  Locations */}
        <div className="flex items-center mt-4">
          <input type="checkbox" id="flower_locations" className="mr-2" checked={params.flower_locations || false} onChange={(e) => setParams({ ...params, flower_locations: e.target.checked })} />
          <label htmlFor="flower_locations" className="text-white">
            Flower Locations
          </label>
        </div>

        {/* Force  Discrete */}
        <div className="flex items-center mt-4">
          <input type="checkbox" id="force_discrete_plot" className="mr-2" checked={params.force_discrete_plot || false} onChange={(e) => setParams({ ...params, force_discrete_plot: e.target.checked })} />
          <label htmlFor="force_discrete_plot" className="text-white">
            Force Discrete
          </label>
        </div>

        {/* Report  Format */}
        <div className="flex items-center mt-4">
          <input type="checkbox" id="force_discrete_plot" className="mr-2" checked={params.force_discrete_plot || false} onChange={(e) => setParams({ ...params, force_discrete_plot: e.target.checked })} />
          <label htmlFor="force_discrete_plot" className="text-white">
            Force Discrete
          </label>
        </div>

        {/* Generate Button */}
        <div className="py-4">
          <PrimaryButton type="submit" disabled={_.isEmpty(params.devices)} className="bg-pollen text-white px-4 py-2 rounded-md w-full hover:bg-pollen-600 flex justify-center items-center">
            {loading ? <LoadingIndicator /> : "Generate"}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default HoneybeeGeneratorForm;
