import { FC, useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

type ManualStringArrayCreatorProps = {
  title?: string;
  values: string[];
  onValueSet: (values: string[]) => void;
};

const ManualStringArrayCreator: FC<ManualStringArrayCreatorProps> = ({ title, values, onValueSet }) => {
  const handleRemove = (index: number) => {
    if (!values) return;
    let newValues = [...values];
    newValues.splice(index, 1);
    onValueSet(newValues);
  };

  const handleAdd = () => {
    if (!values) return;
    onValueSet([...values, ""]);
  };

  useEffect(() => {
    const el = document.getElementById(`entity_${values.length - 1}`);
    if (el) el.focus();
  }, [values.length]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (!values) return;
    let newValues = [...values];
    newValues[index] = e.target.value;
    onValueSet(newValues);
  };

  if (!values) return null;

  return (
    <div>
      {title && <p className="text-white">{title}</p>}
      {values.map((value: string, index: number) => (
        <div key={index} className="relative py-2">
          <input id={`entity_${index}`} className="w-full p-2 border border-slate-700 rounded bg-slate-700 text-white" value={value || ""} onChange={(e) => handleChange(e, index)} />
          <button className="absolute top-0 right-[-0.5rem] text-white text-lg" onClick={() => handleRemove(index)}>
            <AiFillCloseCircle />
          </button>
        </div>
      ))}
      <button className="text-white bg-slate-600 text-sm px-2 py-1 my-4 rounded" onClick={handleAdd}>
        Add
      </button>
    </div>
  );
};

export default ManualStringArrayCreator;
