import moment from "moment";
import { format } from "date-fns";

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function convertToUtc(datetimeString: string) {
  return moment(datetimeString).utc().format("YYYY-MM-DD HH:mm:ss");
}

export const formatSnakeCase = (str: string) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export function formatDatetime(dateString: string) {
  const date = new Date(dateString);
  return format(date, "yyyy-MM-dd HH:mm:ss");
}

export const pollFunction = async (func: Function, args: any[], interval: number) => {
  console.log("polling function");
  let result = null;
  while (result === null) {
    result = await func(...args);
    console.log("result", result);
    if (result !== null) {
      return result;
    }

    await new Promise((resolve) => setTimeout(resolve, interval));
  }
};
