import React, { useEffect } from "react";

interface HTMLViewerProps {
  url: string;
}

const HTMLViewer: React.FC<HTMLViewerProps> = ({ url }) => {
  return (
    <div className="html-viewer w-screen h-screen">
      <iframe src={url} title="HTML Viewer" className="w-full h-full" />
    </div>
  );
};

export default HTMLViewer;
