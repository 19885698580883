import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../utilities";
import { PrimaryButton } from "./Buttons";
import fetchUserAPI from "../services/api";
import LoadingIndicator from "./LoadingIndicator";

const LoginForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    localStorage.clear();
    if (!email || !password) return alert("Please enter your email and password");
    if (!validateEmail(email)) return alert("Please enter a valid email");

    setLoading(true);

    localStorage.setItem("email", email);
    localStorage.setItem("code", password);

    fetchUserAPI("user").then((res) => {
      if (res.code !== 200) return alert("Error logging in");
      if (res.code === 200) {
        if (res.data.admin) localStorage.setItem("admin", "true");
        return navigate("/");
      }
    });

    setLoading(false);
  };

  return (
    <div className="bg-gray-900 rounded-lg shadow-lg p-6 w-80">
      <h2 className="text-2xl font-bold text-white mb-4">Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-300 font-bold mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            className="w-full border border-gray-700 rounded-md py-2 px-3 focus:outline-none focus:ring-pollen-500 focus:border-pollen-500 bg-gray-800 text-white"
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block text-gray-300 font-bold mb-2">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="w-full border mb-4 border-gray-700 rounded-md py-2 px-3 focus:outline-none focus:ring-pollen-500 focus:border-pollen-500 bg-gray-800 text-white"
            placeholder="Enter your password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
        </div>
        {!loading && <PrimaryButton type="submit">Login</PrimaryButton>}
        {loading && <LoadingIndicator />}
      </form>
    </div>
  );
};

export default LoginForm;
