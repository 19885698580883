import classNames from "classnames";
import { FC } from "react";

type LoadingIndicatorProps = {
  color?: string;
};

const LoadingIndicator: FC<LoadingIndicatorProps> = ({ color }) => {
  return <div className={classNames({ "animate-spin rounded-full h-5 w-5 border-b-2 ": true, "border-slate-100 ": !color }) + `border-${color}`}></div>;
};

export default LoadingIndicator;
