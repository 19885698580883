import { FC } from "react";
import LoadingIndicator from "../components/LoadingIndicator";

type LoadingViewProps = {};

const LoadingView: FC<LoadingViewProps> = ({}) => {
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <LoadingIndicator />
    </div>
  );
};

export default LoadingView;
