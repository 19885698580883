import { Routes, Route, BrowserRouter, useNavigate, useLocation } from "react-router-dom";
import GenerateMap from "./pages/GenerateMap";
import LoadingView from "./views/LoadingView";
import { useEffect, useState } from "react";
import LoginPage from "./pages/LoginPage";
import Layout from "./components/Layout";
import MapPage from "./pages/MapPage";
import MapsPage from "./pages/MapsPage";
import { RecoilRoot } from "recoil";

import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import fetchUserAPI from "./services/api";

function RequireAuth({ children }: { children: JSX.Element }): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const code = localStorage.getItem("code");
    const email = localStorage.getItem("email");
    if (!code || !email) navigate("/login", { replace: true });
    fetchUserAPI("user").then((res) => {
      if (res.code !== 200) return navigate("/login", { replace: true });
      if (res.code === 200) return setLoading(false);
    });
  }, [location.pathname]);

  if (loading) return <LoadingView />;

  return children;
}

function App() {
  return (
    <>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <RecoilRoot>
            <Layout>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      <GenerateMap />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/maps"
                  element={
                    <RequireAuth>
                      <MapsPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/map/:id"
                  loader={({ params }) => params.id || ""}
                  element={
                    <RequireAuth>
                      <MapPage />
                    </RequireAuth>
                  }
                />
              </Routes>
            </Layout>
          </RecoilRoot>
        </QueryParamProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
