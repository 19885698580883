interface APIResponse {
  success: boolean;
  message?: any;
  code: number;
  data?: any;
}

const url = "https://ubr6ruk6xj.execute-api.us-west-2.amazonaws.com/dev";

const appendPaths = (path: string) => url + "/" + path;
const mergeSubPaths = (path: string) =>
  path
    .split("/")
    .map((subPath) => subPath.replace(/[/]/, "").trim())
    .join("/");
const parseParams = (params: any) =>
  "?" +
  Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");

const fetchUserAPI = async (endpointName: string, params?: any, method: string = "GET"): Promise<APIResponse> => {
  try {
    const code = localStorage.getItem("code");
    const email = localStorage.getItem("email");

    if (!code || !email) {
      window.location.href = "/login";
      return { success: false, message: "code or email not found", code: 500 };
    }

    let endpoint = appendPaths(mergeSubPaths(endpointName));
    let options: any = {
      method,
      headers: {
        Authorization: `${email}:${code}`,
        Accept: "application/json",
      },
    };

    if (method === "POST" || method === "PUT") {
      options["body"] = JSON.stringify(params);
    } else {
      endpoint = params ? endpoint + parseParams(params) : endpoint;
    }

    const res = await fetch(endpoint, options);
    const data = await res.json();

    if (res.status === 200) {
      return { success: true, data, code: res.status };
    }

    return { success: false, code: res.status, data };
  } catch (error) {
    console.error(`fetch error: ${JSON.stringify(error)}`);
    return { success: false, message: error, code: 500 };
  }
};

export default fetchUserAPI;
