import { FC, useEffect, useState } from "react";
import PageContainer from "../components/PageContainer";
import MapList from "../components/MapList";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { MapParamsState } from "../atoms";
import HTMLViewer from "../components/HTMLViewer";
import fetchUserAPI from "../services/api";
import LoadingView from "../views/LoadingView";

type MapsPageProps = {};

const MapsPage: FC<MapsPageProps> = ({}) => {
  const navigate = useNavigate();
  const [maps, setMaps] = useState<any[]>([]);
  const [params, setParams] = useRecoilState(MapParamsState);

  const [loading, setLoading] = useState(true);

  const handleMapItemClick = (item: any) => {
    setParams(item);
    navigate(`/map/${item.map_name}`);
  };

  const formattedMapItems = (maps: any[]) => {
    return maps.map((map) => {
      return {
        ...map,
        config_json: JSON.parse(map.config_json),
      };
    });
  };

  useEffect(() => {
    fetchUserAPI("maps").then((res) => {
      console.log("got maps", res);
      if (res.code === 200) setMaps(formattedMapItems(res.data));
      setLoading(false);
    });
  }, []);

  if (loading) return <LoadingView />;

  return (
    <PageContainer>
      <MapList maps={maps} onMapItemClick={handleMapItemClick} />
    </PageContainer>
  );
};

export default MapsPage;
