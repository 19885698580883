import { FC } from "react";
import PageContainer from "../components/PageContainer";
import LoginForm from "../components/LoginForm";

type LoginPageProps = {};

const LoginPage: FC<LoginPageProps> = ({}) => {
  return (
    <PageContainer>
      <LoginForm />
    </PageContainer>
  );
};

export default LoginPage;
