import { FC } from "react";
import BumblebeeGeneratorForm from "../components/generator-forms/BumblebeeGeneratorForm";
import SelectDropdown from "../components/inputs/SelectDropdown";
import HoneybeeGeneratorForm from "../components/generator-forms/HoneybeeGeneratorForm";
import RoverGeneratorForm from "../components/generator-forms/RoverGeneratorForm";
import DragonflyGeneratorForm from "../components/generator-forms/DragonflyGeneratorForm"
import EntitySelector from "../components/EntitySelector";
import { useQueryParams, StringParam, JsonParam, BooleanParam } from "use-query-params";
import useUser from "../hooks/useUser";
import LoadingView from "../views/LoadingView";
import fetchUserAPI from "../services/api";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { MapParamsState } from "../atoms";
import { pollFunction } from "../utilities";

type GenerateMapProps = {};

const GenerateMap: FC<GenerateMapProps> = ({}) => {
  const DEFAULT_FORM = "bumblebee";

  const navigate = useNavigate();

  const user = useUser();

  const [selectedMapParams, setSelectedMapParams] = useRecoilState(MapParamsState);

  const [params, setParams] = useQueryParams({
    plot_type: StringParam,
    devices: JsonParam,
    map_color_var: StringParam,
    map_color_scale: StringParam,
    map_color_mapping: JsonParam,
    map_color_range: JsonParam,
    map_hover_data: JsonParam,
    map_suffix: StringParam,
    timestamp_output: BooleanParam,
    map_style: StringParam,
    flower_locations: BooleanParam,
    filter_query: StringParam,
    save_location: StringParam,
    timezone: StringParam,
    hex_aggregation: JsonParam,
    force_discrete_plot: BooleanParam,
    report_format: BooleanParam,
    force_uncached: BooleanParam,
  });

  const resetParams = () => {
    setParams({
      devices: undefined,
      map_color_var: undefined,
      map_color_scale: undefined,
      map_color_mapping: undefined,
      map_color_range: undefined,
      map_hover_data: undefined,
      map_suffix: undefined,
      timestamp_output: undefined,
      map_style: undefined,
      flower_locations: undefined,
      filter_query: undefined,
      save_location: undefined,
      timezone: undefined,
      hex_aggregation: undefined,
      force_discrete_plot: undefined,
      report_format: true,
      force_uncached: undefined,
    });
  };

  // useEffect(() => {
  //   console.log("ENTITIES", entities);
  //   if (entities) setParams({ ...params, devices: entities.map((en) => ({ device: en })) });
  // }, [entities]);

  // useEffect(() => {
  //   setParams({ ...params, plot_type: DEFAULT_FORM });
  // }, []);

  // useEffect(() => {
  //   resetParams();
  // }, [params.plot_type]);

  const fetchMapRecord = async (map_id: string) => {
    const res = await fetchUserAPI(`map`, { map_id }, "GET");

    if (res.code === 200) {
      console.log("map record", res);
      if (res.data.status === "processing") return null;
      if (res.data.status === "complete") return res.data;
      console.log("ERROR");
      return null;
    }

    return null;
  };

  const handleSubmit = async (data: any) => {
    console.log("CONFIG", data);
    const res = await fetchUserAPI("map", data, "POST");
    console.log(res);

    if (res.code === 200) {
      const map_id = res.data.map_id || "";
      const map_name = res.data.map_name || "";

      if (res.data.map_url) {
        navigate(`/map/${map_name}`);
        return;
      }

      const resp = await pollFunction(fetchMapRecord, [map_id], 1000);
      console.log("RESP", resp);
      if (resp) navigate(`/map/${map_name}`);
    }
  };

  const devices_selected = params.devices && params.devices.length > 0;
  if (user.loading) return <LoadingView />;
  return (
    <div className="flex flex-col md:flex-row items-center w-full h-[calc(100vh-var(--navbar-height))] absolute top-[var(--navbar-height)] left-0">
      <div className="bg-slate-900 h-full w-full md:w-1/4 flex flex-col justify-top items-center py-12">
        <div className="w-3/4">
          <h1 className="text-2xl font-bold text-white pb-4">Map Type</h1>
          <div className="mb-4">
            <SelectDropdown value = {params.plot_type} onChange={(e: any) => setParams({ ...params, plot_type: e.target.value })}>
              <option value="bumblebee">Bumblebee</option>
              <option value="bumblebee_cell_scan">Bumblebee Cell Scan</option>
              <option value="bumblebee_energy_scan">Bumblebee Energy Scan</option>
              <option value="honeybee">Honeybee</option>
              <option value="rover">Rover</option>
              <option value="dragonfly">Dragonfly</option>
            </SelectDropdown>
          </div>
          {user.user.bees && user.user.bees.length > 0 && <p className="text-slate-400 text-xs">Shift + Click to select multiple</p>}
          <EntitySelector />
        </div>
      </div>
      <div className="h-full w-full md:w-3/4 flex flex-col justify-start items-center overflow-y-scroll my-4">
        {!devices_selected && <p className="text-white mt-4">Please select devices.</p>}
        {devices_selected && <h1 className="text-2xl font-bold text-white py-4">Generate Map</h1>}
        <div className="w-[99%]">

          {(params.plot_type === "bumblebee" || !params.plot_type) && devices_selected && <BumblebeeGeneratorForm onSubmit={handleSubmit} />}
          {params.plot_type === "bumblebee_cell_scan" && devices_selected && <BumblebeeGeneratorForm onSubmit={handleSubmit} />}
          {params.plot_type === "bumblebee_energy_scan" && devices_selected && <BumblebeeGeneratorForm onSubmit={handleSubmit} />}
          {params.plot_type === "honeybee" && devices_selected && <HoneybeeGeneratorForm onSubmit={handleSubmit} />}
          {params.plot_type === "rover" && devices_selected && <RoverGeneratorForm onSubmit={handleSubmit} />}
          {params.plot_type === "dragonfly" && devices_selected && <DragonflyGeneratorForm onSubmit={handleSubmit} />}
        </div>
      </div>
    </div>
  );
};

export default GenerateMap;
