import { FC } from "react";

import { GeneratorFormProps } from "../../types";
import { StringParam, useQueryParams, JsonParam, BooleanParam } from "use-query-params";
import _ from "lodash";
import BaseGeneratorForm from "./BaseGeneratorForm";


const BumblebeeGeneratorForm: FC<GeneratorFormProps> = ({ onSubmit }) => {
  const [params, setParams] = useQueryParams({
    plot_type: StringParam,
    devices: JsonParam,
    map_color_var: StringParam,
    map_color_scale: StringParam,
    map_color_mapping: JsonParam,
    map_color_range: JsonParam,
    map_hover_data: JsonParam,
    map_suffix: StringParam,
    timestamp_output: BooleanParam,
    map_style: StringParam,
    flower_locations: BooleanParam,
    filter_query: StringParam,
    save_location: StringParam,
    timezone: StringParam,
    hex_aggregation: JsonParam,
    force_discrete_plot: BooleanParam,
    report_format: BooleanParam,
    force_uncached: BooleanParam,
    // UI control params
    use_hex_aggregation: BooleanParam,
    use_advanced_map_options: BooleanParam,
  });
  

  var optionsArray = [
    { value: "Device", label: "Device" },
    { value: "Time", label: "Time" },
    { value: "Latitude", label: "Latitude" },
    { value: "Longitude", label: "Longitude" },
    { value: "Altitude", label: "Altitude" },
    { value: "Attach State", label: "Attach State" },
    { value: "Flower", label: "Flower" },
    { value: "Num Sats", label: "Num Sats" },
    { value: "H3 Hex Res9", label: "H3 Hex Res9" },
    { value: "H3 Hex Res5", label: "H3 Hex Res5" },
    { value: "H3 Hex Res11", label: "H3 Hex Res11" },
    { value: "Logfile", label: "Logfile" },
    { value: "Lte Band", label: "Lte Band" },
    { value: "Mcc", label: "Mcc" },
    { value: "Mnc", label: "Mnc" },
    { value: "Iccid", label: "Iccid" },
    { value: "Imei", label: "Imei" },
    { value: "Imsi", label: "Imsi" },
    { value: "Rsrp Main", label: "Rsrp Main" },
    { value: "Rsrp Secondary", label: "Rsrp Secondary" },
    { value: "Rssi Main", label: "Rssi Main" },
    { value: "Rssi Secondary", label: "Rssi Secondary" },
    { value: "Rsrq", label: "Rsrq" },
    { value: "Rx Channel", label: "Rx Channel" },
    { value: "Cell Id", label: "Cell Id" },
    { value: "Sinr", label: "Sinr" },
    { value: "Lte State", label: "Lte State" },
    { value: "Tac", label: "Tac" },
    { value: "Tx Channel", label: "Tx Channel" },
    { value: "Ping", label: "Ping" }
  ];

  if (params.plot_type === "bumblebee_cell_scan") {
    optionsArray = [
      { value: "Plmns", label: "Plmns" },
      { value: "H3 Hex Res11", label: "H3 Hex Res11" },
      { value: "Device", label: "Device" },
      { value: "Cell Id", label: "Cell Id" },
      { value: "Rx Channel", label: "Rx Channel" },
      { value: "Longitude", label: "Longitude" },
      { value: "Latitude", label: "Latitude" },
      { value: "H3 Hex Res9", label: "H3 Hex Res9" },
      { value: "Band", label: "Band" },
      { value: "Time", label: "Time" }
    ];
  }

  if (params.plot_type === "bumblebee_energy_scan") {
    optionsArray = [
      { value: "Time", label: "Time" },
      { value: "H3 Hex Res11", label: "H3 Hex Res11" },
      { value: "Device", label: "Device" },
      { value: "Longitude", label: "Longitude" },
      { value: "Latitude", label: "Latitude" },
      { value: "H3 Hex Res9", label: "H3 Hex Res9" },
      { value: "Earfcn", label: "Earfcn" },
      { value: "Energy", label: "Energy" },
      { value: "Band", label: "Band" },
      { value: "Bandwidth", label: "Bandwidth" }
    ];
  }
  
  return <BaseGeneratorForm onSubmit={onSubmit} options = {optionsArray}/>

};

export default BumblebeeGeneratorForm;
