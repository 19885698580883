import React, { ButtonHTMLAttributes } from "react";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const PrimaryButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <button className="bg-pollen hover:bg-pollen text-white font-bold py-1 px-3 rounded" {...rest}>
      {children}
    </button>
  );
};

const SecondaryButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <button className="bg-slate-700 hover:bg-slate-800 text-white font-bold py-0.5 px-2 rounded" {...rest}>
      {children}
    </button>
  );
};

const TextButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <button className="text-pollen hover:text-pollen font-bold" {...rest}>
      {children}
    </button>
  );
};

export { PrimaryButton, SecondaryButton, TextButton };
