import { FC, useMemo } from "react";
import ButtonGroup from "./ButtonGroup";
import LoadingIndicator from "./LoadingIndicator";
import useUser from "../hooks/useUser";
import { JsonParam, StringParam, useQueryParam } from "use-query-params";
import ManualStringArrayCreator from "./ManualStringArrayCreator";

type EntitySelectorProps = {};

const EntitySelector: FC<EntitySelectorProps> = ({}) => {
  const MAX_BEES = 5;
  const user = useUser();

  const [form] = useQueryParam("plot_type", StringParam);
  const [rawDevices, setDevices] = useQueryParam("devices", JsonParam);

  const handleSetDevices = (entities: string[]) => setDevices(entities.map((entity: string) => ({ device: entity })));

  const entities: string[] = useMemo(() => {
    if (!rawDevices) return [];
    return [...rawDevices].reduce((acc: string[], cur: string | null) => (cur ? [...acc, cur] : acc), []);
  }, [rawDevices]);

  let bumblebees = useMemo(() => {
    if (!user.user.bees) return [];
    return user.user.bees.filter((bee: string) => bee.toLowerCase().includes("bumblebee"));
  }, [user?.user?.bees]);

  let honeybees = useMemo(() => {
    if (!user.user.bees) return [];
    return user.user.bees.filter((bee: string) => bee.toLowerCase().includes("honeybee"));
  }, [user?.user?.bees]);

  if (user.loading) {
    return <LoadingIndicator />;
  }

  const selected_device_names = entities.map((en: any) => en.device);

  if (form === "bumblebee_cell_scan") {
    if (user.user.admin) {
      return <ManualStringArrayCreator title="Add Bumblebees" values={selected_device_names} onValueSet={handleSetDevices} />;
    }
    return <ButtonGroup options={bumblebees} selectedOptions={selected_device_names} onOptionsSelected={handleSetDevices} maxSelected={MAX_BEES} />;
  }

  if (form === "bumblebee_energy_scan") {
    if (user.user.admin) {
      return <ManualStringArrayCreator title="Add Bumblebees" values={selected_device_names} onValueSet={handleSetDevices} />;
    }
    return <ButtonGroup options={bumblebees} selectedOptions={selected_device_names} onOptionsSelected={handleSetDevices} maxSelected={MAX_BEES} />;
  }

  if (form === "honeybee") {
    if (user.user.admin) {
      return <ManualStringArrayCreator title="Add Honeybees" values={selected_device_names} onValueSet={handleSetDevices} />;
    }
    return <ButtonGroup options={honeybees} selectedOptions={selected_device_names} onOptionsSelected={handleSetDevices} maxSelected={MAX_BEES} />;
  }

  if (form === "rover") {
    if (user.user.admin) {
      return <ManualStringArrayCreator title="Add Rovers" values={selected_device_names} onValueSet={handleSetDevices} />;
    }
    return null;
  }

  if (form === "dragonfly") {
    if (user.user.admin) {
      return <ManualStringArrayCreator title="Add Dragonflies" values={selected_device_names} onValueSet={handleSetDevices} />;
    }
    return null;
  }
  {/*Default is Bumblebee */}
  if (user.user.admin) {
    return <ManualStringArrayCreator title="Add Bumblebees" values={selected_device_names} onValueSet={handleSetDevices} />;
  }
  return <ButtonGroup options={bumblebees} selectedOptions={selected_device_names} onOptionsSelected={handleSetDevices} maxSelected={MAX_BEES} />;
};

export default EntitySelector;
