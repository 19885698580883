import { atom } from "recoil";
// import { MapItem } from "./types";

export const UserState = atom<any>({
  key: "UserState",
  default: null,
});

export const MapParamsState = atom<any>({
  key: "MapParamsState",
  default: null,
});
