import moment from "moment";
import { config } from "process";
import { MapItem } from "../types";

type CardProps = {
  item: MapItem;
  onClick: (item: MapItem) => void;
};
const Card: React.FC<CardProps> = ({ item, onClick }) => {
  const { config_json } = item;

  console.log(item);

  return (
    <div className="bg-slate-700 hover:bg-slate-800 border-2 m-4 border-slate-600 shadow-lg p-4 rounded-lg cursor-pointer" onClick={() => onClick(item)}>
      <div className="text-sm text-gray-400">
        <h2 className="text-xs font-normal text-gray-500">{item.map_id}</h2>
        {config_json.bees && (
          <ul className="list-none p-0">
            {config_json.bees.map((bee: any, index: number) => (
              <li key={index}>
                <p className="text-lg text-white">{`${bee.bee_name}`}</p>
                <p>{bee.start_time && ` Start Time: ${moment(bee.start_time).format("YYYY-MM-DD HH:mm:ss")}`}</p>
                <p>{bee.start_time_offset !== undefined && ` Start Time Offset: ${bee.start_time_offset}`}</p>
                <p>{bee.end_time && ` End Time: ${moment(bee.end_time).format("YYYY-MM-DD HH:mm:ss")}`}</p>
                <p>{bee.end_time_offset !== undefined && ` End Time Offset: ${bee.end_time_offset}`}</p>
                <p>{item.timezone && ` Timezone: ${item.timezone}`}</p>
              </li>
            ))}
          </ul>
        )}
        {config_json.map_suffix && <p>{`Map Suffix: ${config_json.map_suffix}`}</p>}
        {config_json.map_color && <p>{`Map Type: ${config_json.map_color}`}</p>}
        {config_json.map_style && <p>{`Map Style: ${config_json.map_style}`}</p>}
        {config_json.save_location && <p>{`Save Location: ${config_json.save_location}`}</p>}
        {config_json.timezone && <p>{`Timezone: ${config_json.timezone}`}</p>}
        {config_json.force_uncached && <p>{`Force Uncached: ${config_json.force_uncached}`}</p>}
        {config_json.report_format && <p>{`Report Format: ${config_json.report_format}`}</p>}
        {config_json.map_description && <p>{`Description: ${config_json.map_description}`}</p>}
      </div>
    </div>
  );
};

type MapListProps = {
  maps: MapItem[];
  onMapItemClick: (item: MapItem) => void;
};

const MapList: React.FC<MapListProps> = ({ maps, onMapItemClick }) => {
  return (
    <div className="flex flex-wrap justify-center items-start overflow-y-scroll relative z-[1]">
      {maps.map((map) => (
        <Card item={map} onClick={onMapItemClick} />
      ))}
    </div>
  );
};

export default MapList;
