import { FC } from "react";

type SelectDropdownProps = {
  [key: string]: any;
  children: React.ReactNode;
};

const SelectDropdown: FC<SelectDropdownProps> = ({ children, ...rest }) => {
  return (
    <select {...rest} className="w-full p-2 border border-slate-700 bg-slate-700 rounded text-white">
      {children}
    </select>
  );
};

export default SelectDropdown;
