import { FC, ReactNode } from "react";

type PageContainerProps = {
  children: ReactNode;
};

const PageContainer: FC<PageContainerProps> = ({ children }) => {
  return <div className="w-screen h-[calc(100vh-calc(var(--navbar-height)+var(--footer-height)))] fixed top-[var(--navbar-height)] left-0 flex flex-col justify-center items-center">{children}</div>;
};

export default PageContainer;
