import { FC, useEffect, useState } from "react";
import PageContainer from "../components/PageContainer";
import { useParams } from "react-router-dom";
import HTMLViewer from "../components/HTMLViewer";
import { useRecoilValue } from "recoil";
import { MapParamsState } from "../atoms";
import fetchUserAPI from "../services/api";
import LoadingView from "../views/LoadingView";
import { CARTOGRAPHER_PLOTS } from "../config";

type MapPageProps = {};

const MapPage: FC<MapPageProps> = ({}) => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [mapData, setMapData] = useState<any>({});

  
  const handleLoadMap = async () => {
    if (!params.id) return;
    const res = await fetchUserAPI("sign", { bucket: CARTOGRAPHER_PLOTS, key: params.id + ".html" }, "GET");
    console.log("sign Res", res);
    if (res.code === 200) {
      setMapData({ map_url: res.data.url });
    }
  };

  useEffect(() => {
    if (!params.id) return;
    handleLoadMap().then(() => setLoading(false));
  }, [params.id]);
  
  const handleDownloadHTML = async () => {
    
    if (!mapData.map_url) return;
    const map_url = mapData.map_url 
    // Fetch the HTML content from the map_url
    const response = await fetch(map_url);
    const htmlContent = await response.text();
    // Create a new Blob object with the HTML content
    const blob = new Blob([htmlContent], { type: 'text/html' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary link and click it to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = params.id + ".html";
    link.click();

    // Clean up the URL and the temporary link
    URL.revokeObjectURL(url);
  };

  if (loading) return <LoadingView />;

  return (
    <PageContainer>
      <div>
        <button onClick={handleDownloadHTML} className = "text-white">Download HTML</button>
      </div>
      <HTMLViewer url={mapData.map_url || ""} />
    </PageContainer>
  );
};

export default MapPage;
