import { useEffect, useState } from "react";
import fetchUserAPI from "../services/api";
import { useRecoilState } from "recoil";
import { UserState } from "../atoms";
import _ from "lodash";

const useUser = () => {
  const [user, setUser] = useRecoilState(UserState);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchUser = async () => {
    if (user) return;
    const res = await fetchUserAPI("user");
    console.log("user data", res.data);
    if (res.code === 200) {
      setUser(res.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) return setLoading(false);
    setLoading(true);
    fetchUser().then();
  }, []);

  return { user, loading };
};

export default useUser;
