import React, { useState } from "react";

interface ButtonGroupProps {
  options: string[];
  selectedOptions: string[];
  onOptionsSelected: (option: string[]) => void;
  maxSelected?: number;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ options, selectedOptions, onOptionsSelected, maxSelected = 1 }) => {
  const handleClick = (event: any, option: string) => {
    event.preventDefault();
    let selections = [...selectedOptions];

    if (event.shiftKey && maxSelected > 1) {
      if (selections.includes(option)) return;
      selections.push(option);
    } else {
      selections = [option];
    }

    onOptionsSelected(selections);
  };

  return (
    <div className="flex flex-col justify-center items-center w-full">
      {options.map((option, index) => (
        <button key={index} className={`text-sm md:text-lg p-4 my-2 rounded w-full ${selectedOptions.includes(option) ? "bg-slate-500 text-white" : "bg-slate-700 text-white"}`} onClick={(event) => handleClick(event, option)}>
          {option}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
