import  { FC } from "react";

import { GeneratorFormProps } from "../../types";

import BaseGeneratorForm from "./BaseGeneratorForm";

const RoverGeneratorForm: FC<GeneratorFormProps> = ({ onSubmit }) => {
  const optionsArray = [
    { value: "Device", label: "Device" },
    { value: "Time", label: "Time" },
    { value: "Latitude", label: "Latitude" },
    { value: "Longitude", label: "Longitude" },
    { value: "Altitude", label: "Altitude" },
    { value: "Attach State", label: "Attach State" },
    { value: "Flower", label: "Flower" },
    { value: "Num Sats", label: "Num Sats" },
    { value: "H3 Hex Res9", label: "H3 Hex Res9" },
    { value: "H3 Hex Res5", label: "H3 Hex Res5" },
    { value: "H3 Hex Res11", label: "H3 Hex Res11" },
    { value: "Lte Band", label: "Lte Band" },
    { value: "Network Name", label: "Network Name" },
    { value: "Iccid", label: "Iccid" },
    { value: "Imei", label: "Imei" },
    { value: "Ismi", label: "Ismi" },
    { value: "Rsrp Main", label: "Rsrp Main" },
    { value: "Rsrp Secondary", label: "Rsrp Secondary" },
    { value: "Rssi Main", label: "Rssi Main" },
    { value: "Rssi Secondary", label: "Rssi Secondary" },
    { value: "Rsrq", label: "Rsrq" },
    { value: "Rx Channel", label: "Rx Channel" },
    { value: "Cell Id", label: "Cell Id" },
    { value: "Sinr", label: "Sinr" },
    { value: "Lte State", label: "Lte State" },
    { value: "Tac", label: "Tac" },
    { value: "Tx Channel", label: "Tx Channel" },
    { value: "Ping", label: "Ping" }
  ];
  return <BaseGeneratorForm onSubmit={onSubmit} options = {optionsArray}/>
};


export default RoverGeneratorForm;
